<div class="spinner-border" role="status" *ngIf="loading">
  <span class="visually-hidden">{{'shared.carregando' | translate}}</span>
</div>

<div *ngIf="!loading && exibir">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto">{{'pagamento_boleto.pagamento_boleto' | translate}}</legend>
    <p class="small mt-2">{{'pagamento_boleto.clique_gerar_boleto' | translate}}</p>
    <button type="button" (click)="imprimir()" class="botao_boleto btn btn-primary btn-sm">
      <i class="bi bi-printer" aria-hidden="true">&nbsp;</i> {{'pagamento_boleto.download_boleto' | translate}}
    </button>
  </fieldset>
</div>

<div *ngIf="aguardandoAprovacaoReducao">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto mb-1">{{'pagamento_shared.pagamento' | translate}}</legend>
    <ul class="mb-0">
      <li class="text-danger">{{'pagamento_shared.aguardando_decisao_reducao' | translate}}</li>
    </ul>
  </fieldset>
</div>

<div *ngIf="problemaNoBoleto">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto mb-1">{{'pagamento_shared.pagamento' | translate}}</legend>
    <ul class="mb-0">
      <li class="text-danger">{{'pagamento_boleto.boleto_nao_disponivel' | translate}}</li>
    </ul>
  </fieldset>
</div>
