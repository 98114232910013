/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { RecursoDetalhes } from 'src/app/models/recurso-detalhes'
import { DownloadService } from 'src/app/services/download.service'
import { RecursoService } from 'src/app/services/recurso.service'

@Component({
  selector: 'app-visualizar-listar',
  templateUrl: './recurso-visualizar.component.html',
  styleUrls: ['./recurso-visualizar.component.css'],
})
export class RecursoVisualizarComponent implements OnInit {
  recurso?: RecursoDetalhes
  loading: boolean = true

  constructor(
    private downloadService: DownloadService,
    private route: ActivatedRoute,
    private recursoService: RecursoService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let id = params['id']
      this.recursoService.get(id).subscribe(recurso => {
        this.recurso = recurso
        this.loading = false
      })
    })
  }

  print(): void {
    window.print()
  }

  open(): void {
    this.downloadService.recurso(this.recurso?.id ?? 0)
  }

  openParecer(): void {
    this.downloadService.anexoParecer(this.recurso?.id ?? 0)
  }
}
