/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { fromEvent, take } from 'rxjs'
import { EventTypes } from '../../models/event-types'

declare var $: any

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css'],
})
export class ToastComponent implements OnInit {
  @Output() disposeEvent = new EventEmitter()
  @Input() type!: EventTypes
  @Input() title!: string
  @Input() message!: string

  ngOnInit() {
    this.show()
  }

  show() {
    $('.toast')
      .last()
      .toast({ delay: this.type === EventTypes.Error ? 5000 : 3000 })
    $('.toast').last().toast('show')
    fromEvent($('.toast').last(), 'hidden.bs.toast')
      .pipe(take(1))
      .subscribe(() => this.hide())
  }

  hide() {
    this.disposeEvent.emit()
  }
}
