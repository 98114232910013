/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, Input } from '@angular/core'
import { Inscricao } from 'src/app/models/inscricao'
import { DownloadService } from 'src/app/services/download.service'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { ToastService } from '../../toast/services/toast.service'
import { BoletoModalService } from '../boleto-preview-modal/services/boleto-modal.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-pagamento-boleto',
  templateUrl: './pagamento-boleto.component.html',
  styleUrls: ['./pagamento-boleto.component.css'],
})
export class PagamentoBoletoComponent implements OnInit {
  @Input() inscricao?: Inscricao
  loading: boolean = false
  file?: File | null
  inputClass: string = 'form-control'
  exibir: boolean = false
  aguardandoAprovacaoReducao: boolean = false
  problemaNoBoleto: boolean = false
  i18n = {
    atencao: '',
    boletoNaoDisponivel: '',
    inscricao: ''
  }

  constructor(
    private inscricaoService: InscricaoService,
    private downloadService: DownloadService,
    private toastService: ToastService,
    private boletoModalService: BoletoModalService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.carregarI18n();
    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });
    
    if (this.inscricao?.periodoInscricao && this.inscricao?.boleto == 'SIM') {
      if (this.inscricao?.reducao && !this.inscricao.reducaoConcluida) {
        this.aguardandoAprovacaoReducao = true
      } else if (
        this.inscricao?.periodoInscricao &&
        !this.inscricao?.pago &&
        !this.aguardandoAprovacaoReducao &&
        this.inscricao.boletoSolicitado
      ) {
        this.exibir = true
      } else if (!this.inscricao?.pago) {
        this.problemaNoBoleto = true
      }
    }
  }

  imprimir(): void {
    if (this.inscricao) {
      this.loading = true
      let tipo = 'BOLETO'
      this.inscricaoService.boleto(this.inscricao.id, tipo).subscribe(boleto => {
        if (boleto === null) {
          this.toastService.showErrorToast(
            this.i18n.atencao,
            this.i18n.boletoNaoDisponivel
          )
        } else if (boleto.message == 'Ok') {
          this.boletoModalService.showPreview(boleto.url, `Boleto - ${this.i18n.inscricao} #${this.inscricao?.id}`)
        } else {
          this.toastService.showErrorToast('Atenção', boleto.message)
        }
        this.loading = false
      })
    }
  }

  private carregarI18n(): void {
    this.translateService.get([
      'shared.atencao',
      'pagamento_boleto.boleto_nao_disponivel',
      'pagamento_shared.inscricao',
    ])
    .subscribe(traducoes =>{
      this.i18n.atencao = traducoes['shared.atencao']
      this.i18n.boletoNaoDisponivel = traducoes['pagamento_boleto.boleto_nao_disponivel']
      this.i18n.inscricao = traducoes['pagamento_shared.inscricao']
    })
  }
}
