/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Input, OnInit } from '@angular/core'
import { ModalService } from '../services/modal.service'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { FilePreview } from '../models/file-preview'

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit {
  filePreview?: FilePreview
  exibir: boolean = false
  @Input() trustedUrl!: SafeUrl

  constructor(private sanitizer: DomSanitizer, private modalService: ModalService) {}

  ngOnInit(): void {
    this.subscribeToModal()
  }

  subscribeToModal() {
    this.modalService.modalEventsArquivo.subscribe(filePreview => {
      this.filePreview = filePreview
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(filePreview.file))
      this.toggle()
    })
  }

  toggle(): void {
    this.exibir = !this.exibir
  }

  cancelar(): void {
    this.toggle()
    this.modalService.enviar(false)
  }

  enviar(): void {
    this.toggle()
    this.modalService.enviar(true)
  }
}
