<div [class.collapse]="!collapsed" id="inscricao-notas{{ id }}" *ngIf="notas?.length">
  <div class="mb-3">
    <fieldset class="border rounded-3 p-2 mt-0">
      <legend class="w-auto">{{'candidato_inscricao_notas.notas' | translate}}</legend>
      <div class="mt-3 mb-2">
        <div class="row">
          <div
            class="col-xs-12 col-sm-12 col-md-12 course-tabs-content"
            id="notas-tabs-content-{{ id }}"
            style="margin-top: -1%; margin-bottom: -2%">
            <article id="NOTAS-{{ id }}" class="course-tab-item" style="display: block">
              <div class="panel panel-default">
                <ng-container *ngFor="let nota of notas; let idx = index">
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr
                        class="titulo-prova"
                        data-bs-toggle="collapse"
                        attr.href=".inscricao-{{ id }}-notas-{{ idx }}"
                        role="button">
                        <th>
                          <span
                            class="course-tab-item-title"
                            style="line-height: 1.8em; font-size: 11pt; font-weight: bold">
                            {{ nota.tipoProva }}</span
                          >
                        </th>
                        <th class="col-2 col-xs-1 col-sm-2 col-md-2 pe-2">
                          <div class="nota-valor  inscricao-{{ id }}-notas-{{ idx }} collapse show delay-transicao">
                            {{ nota.nota1 | number : nota.notaFormat }}
                          </div>
                        </th>
                        <th class="col-2 col-xs-1 col-sm-2 col-md-2 pe-2">
                          <div class="nota-valor  inscricao-{{ id }}-notas-{{ idx }} collapse show delay-transicao">
                            {{ nota.nota2 | number : nota.notaFormat }}
                          </div>
                        </th>
                        <th class="col-2 col-xs-1 col-sm-2 col-md-2 pe-2">
                          <div class="nota-valor  inscricao-{{ id }}-notas-{{ idx }} collapse show delay-transicao">
                            {{ nota.nota3 | number : nota.notaFormat }}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="inscricao-{{ id }}-notas-{{ idx }} collapse"
                      app-inscricao-nota-criterio
                      [criterios]="nota.criterios"></tbody>
                    <tfoot class="inscricao-{{ id }}-notas-{{ idx }} collapse">
                      <tr class="soma-nota-prova">
                        <td class="col-6 col-xs-9 col-sm-6 col-md-6 ps-3">
                          <span class="course-tab-item-title" style="line-height: 1.8em; font-size: 10pt"
                            >{{'candidato_inscricao_notas.nota_da_prova' | translate}}</span
                          >
                        </td>
                        <td class="col-2 col-xs-1 col-sm-2 col-md-2 pe-2">
                          <div class="nota-valor">{{ nota.nota1 | number : nota.notaFormat }}</div>
                        </td>
                        <td class="col-2 col-xs-1 col-sm-2 col-md-2 pe-2">
                          <div class="nota-valor">{{ nota.nota2 | number : nota.notaFormat }}</div>
                        </td>
                        <td class="col-2 col-xs-1 col-sm-2 col-md-2 pe-2">
                          <div class="nota-valor">{{ nota.nota3 | number : nota.notaFormat }}</div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </ng-container>
              </div>
            </article>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</div>
