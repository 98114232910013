/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Input } from '@angular/core'
import { TipoDocumento } from 'src/app/enums/tipo-documento'
import { InscricaoDocumento } from 'src/app/models/inscricao-documento'
import { DownloadService } from 'src/app/services/download.service'

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css'],
})
export class DocumentosComponent {
  @Input() documentos?: InscricaoDocumento[] = []
  @Input() id?: number = 0
  @Input() idConcurso?: number = 0
  @Input() collapsed: boolean = false

  constructor(private downloadService: DownloadService) {}

  tipoDocumentoStr(tipo: string): string {
    return (TipoDocumento as any)[tipo]
  }

  open(doc: InscricaoDocumento): void {
    this.downloadService.inscricaoDocumento(doc.id)
  }
}
