/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Observable } from 'rxjs/internal/Observable'
import { Subscription } from 'rxjs/internal/Subscription'
import { InscricaoComplementoSituacao } from 'src/app/models/inscricao-complemento-situacao'
import { InscricaoComplementoService } from 'src/app/services/inscricao-complemento.service'
import { ToastService } from '../../toast/services/toast.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-formulario-situacao',
  templateUrl: './formulario-situacao.component.html',
  styleUrls: ['./formulario-situacao.component.css'],
})
export class FormularioSituacaoComponent implements OnInit, OnDestroy {
  private eventsSubscription?: Subscription
  @Input() events?: Observable<void>
  @Input() idInscricao?: number
  loading: boolean = true
  showDivComplemento: boolean = false
  opcionalPendente: boolean = false
  inscricaoComplementoSituacao?: InscricaoComplementoSituacao
  tipoFase1: string = 'PENDENTE'
  tipoFase2: string = 'PENDENTE'
  i18n = {
    parabens: '',
    itensNecessariosEnviados: ''
  }

  constructor(
    private inscricaoComplementoService: InscricaoComplementoService, 
    private toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.carregarI18n();
    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });

    this.eventsSubscription = this.events?.subscribe(() => {
      this.getData(true)
    })
    this.getData(false)
  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe()
  }

  getData(notificar: Boolean): void {
    if (this.idInscricao) {
      this.inscricaoComplementoService.pendencia(this.idInscricao).subscribe(situacao => {
        if (situacao != null) {
          this.showDivComplemento = true
          this.inscricaoComplementoSituacao = situacao
          this.loading = false

          this.opcionalPendente = situacao.quantidadeMinimaOpcionalRequerido > situacao.totalOpcionalEnviado
          if (situacao.totalObrigatorioPendente == 0 && !this.opcionalPendente) {
            this.tipoFase1 = 'COMPLETA'
            if (notificar) {
              this.toastService.showSuccessToast(this.i18n.parabens, this.i18n.itensNecessariosEnviados)
            }
          } else if (!situacao.periodoInscricao && (situacao.totalObrigatorioPendente > 0 || this.opcionalPendente)) {
            this.tipoFase1 = 'INCOMPLETA'
          } else {
            this.tipoFase1 = 'PENDENTE'
          }

          if (situacao.totalObrigatorioPendenteSegundaFase == 0 && situacao.hasComplementoSegundaFase) {
            this.tipoFase2 = 'COMPLETA'
            if (notificar) {
              this.toastService.showSuccessToast(this.i18n.parabens, this.i18n.itensNecessariosEnviados)
            }
          } else if (!situacao.periodoSegundaFase && situacao.totalObrigatorioPendenteSegundaFase > 0) {
            this.tipoFase2 = 'INCOMPLETA'
          } else {
            this.tipoFase2 = 'PENDENTE'
          }
        }
      })
    }
  }

  private carregarI18n(): void {
    this.translateService.get([
      'shared.parabens',
      'formulario_situacao.itens_necessarios_enviados',
    ]).subscribe(traducoes => {
      this.i18n.parabens = traducoes['shared.parabens']
      this.i18n.itensNecessariosEnviados = traducoes['formulario_situacao.itens_necessarios_enviados']
    })
  }
}
