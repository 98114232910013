/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { FilePreview } from '../models/file-preview'

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  modalEventsEnviar: Observable<boolean>
  modalEventsArquivo: Observable<FilePreview>
  private _modalEventsEnviar = new Subject<boolean>()
  private _modalEventsArquivo = new Subject<FilePreview>()

  constructor() {
    this.modalEventsEnviar = this._modalEventsEnviar.asObservable()
    this.modalEventsArquivo = this._modalEventsArquivo.asObservable()
  }

  showPreview(file: File, title: string, subtitle: string): void {
    this._modalEventsArquivo.next({
      file,
      title,
      subtitle,
    })
  }

  enviar(result: boolean): void {
    this._modalEventsEnviar.next(result)
  }
}
