<section>
  <div class="container">
    <div class="alert alert-success">
      <h4>{{'senha_recuperar.recuperacao_senha' | translate}}</h4>
      {{'senha_recuperar.recuperacao_descricao' | translate}}
    </div>
    <div class="row">
      <div class="col-lg-12">
        <form [formGroup]="form">
          <div class="input-group mb-3">
            <span class="input-group-text">Email</span>
            <input
              type="email"
              class="form-control"
              aria-label="email"
              placeholder="{{'senha_shared.informe_seu_email' | translate}}"
              autofocus="autofocus"
              autocomplete="off"
              formControlName="email"
              [email]="true" />
          </div>
          <div class="form-group">
            <a class="btn btn-primary btn-md" (click)="enviar()" style="width: 100%"
              ><i class="bi bi-envelope" aria-hidden="true">&nbsp;</i> {{'shared.enviar' | translate}}</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
