/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, Input } from '@angular/core'
import { Inscricao } from 'src/app/models/inscricao'
import { DownloadService } from 'src/app/services/download.service'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { ToastService } from '../../toast/services/toast.service'
import { PixModalService } from '../pix-preview-modal/services/pix-modal.service'
import { TranslateService } from '@ngx-translate/core'
@Component({
  selector: 'app-pagamento-pix',
  templateUrl: './pagamento-pix.component.html',
  styleUrls: ['./pagamento-pix.component.css'],
})
export class PagamentoPixComponent implements OnInit {
  @Input() inscricao?: Inscricao
  loading: boolean = false
  file?: File | null
  inputClass: string = 'form-control'
  exibir: boolean = false
  aguardandoAprovacaoReducao: boolean = false
  problemaNoPix: boolean = false
  possuiCodigoPix: boolean = false
  i18n = {
    atencao: '',
    pixNaoDisponivel: '',
    inscricao: '',
    problemaEmissao: ''
  }

  constructor(
    private inscricaoService: InscricaoService,
    private downloadService: DownloadService,
    private toastService: ToastService,
    private pixModalService: PixModalService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.carregarI18n();
    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });

    if (this.inscricao?.periodoInscricao && this.inscricao?.pix == 'SIM') {
      if (this.inscricao?.reducao && !this.inscricao.reducaoConcluida) {
        this.aguardandoAprovacaoReducao = true
      } else if (
        this.inscricao?.periodoInscricao &&
        !this.inscricao?.pago &&
        !this.aguardandoAprovacaoReducao &&
        this.inscricao.pixSolicitado
      ) {
        this.exibir = true
      } else if (!this.inscricao?.pago) {
        this.problemaNoPix = true
      }
    }
  }

  exibirQrCode(): void {
    if (this.inscricao) {
      this.loading = true
      let tipo = 'PIX'
      this.inscricaoService.pix(this.inscricao.id, tipo).subscribe(pix => {
        if (pix === null) {
          this.toastService.showErrorToast(
            this.i18n.atencao,
            this.i18n.pixNaoDisponivel
          )
        } else if (pix.message == 'Ok') {
          this.pixModalService.showPreview(pix.url, `Pix - ${this.i18n.inscricao} #${this.inscricao?.id}`, pix.text)
        } else {
          this.toastService.showErrorToast(this.i18n.atencao, `${this.i18n.problemaEmissao} - ${pix.message}`)
        }
        this.loading = false
      })
    }
  }
  
  private carregarI18n(): void {
    this.translateService.get([
      'shared.atencao',
      'pagamento_pix.pix_nao_disponivel',
      'pagamento_pix.problema_emissao',
      'pagamento_shared.inscricao'
    ])
    .subscribe(traducoes =>{
      this.i18n.atencao = traducoes['shared.atencao']
      this.i18n.pixNaoDisponivel = traducoes['pagamento_pix.pix_nao_disponivel']
      this.i18n.problemaEmissao = traducoes['pagamento_pix.problema_emissao']
      this.i18n.inscricao = traducoes['pagamento_shared.inscricao']
    })
  }
}
