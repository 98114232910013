<div class="spinner-border" role="status" *ngIf="loading">
  <span class="visually-hidden">{{'shared.carregando' | translate}}</span>
</div>

<div *ngIf="!loading && exibir">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto">{{'pagamento_devolucao.devolucao_taxa' | translate}}</legend>

    <div *ngIf="inscricao?.devolucaoConcluida">
      <p class="small mt-2">
        {{'pagamento_devolucao.detalhes_solicitacao' | translate}}:
        <b
          >{{ inscricao?.devolucaoBanco }} - {{ inscricao?.devolucaoAgencia }} - {{ inscricao?.devolucaoConta }} -
          {{ inscricao?.devolucaoVariacao }}</b
        >
        <br /><small class="btn btn-success btn-sm"
          ><i class="bi bi-check">&nbsp;</i>{{'pagamento_devolucao.concluido_em' | translate}}:
          {{ inscricao?.devolucaoData | date : 'dd/MM/yyyy HH:mm:ss' }}</small
        >
      </p>
    </div>

    <div *ngIf="!inscricao?.devolucaoConcluida && !inscricao?.devolucaoSolicitada">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div id="group_banco" class="input-group">
              <span class="input-group-text">{{'pagamento_shared.banco' | translate}}</span>
              <input class="form-control" required="" id="banco" name="banco" formControlName="banco" type="text" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div id="group_agencia" class="input-group">
              <span class="input-group-text">{{'pagamento_shared.agencia' | translate}}</span>
              <input
                class="form-control"
                required=""
                id="agencia"
                name="agencia"
                formControlName="agencia"
                type="text" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div id="group_conta" class="input-group">
              <span class="input-group-text">{{'pagamento_shared.cc' | translate}}</span>
              <input class="form-control" required="" id="conta" name="conta" formControlName="conta" type="text" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div id="group_variacao" class="input-group">
              <span class="input-group-text">{{'pagamento_devolucao.variacao' | translate}}</span>
              <input
                class="form-control"
                required=""
                id="variacao"
                name="variacao"
                formControlName="variacao"
                type="text" />
            </div>
          </div>
        </div>
      </form>
      <button type="button" (click)="enviar()" class="btn btn-primary btn-flat mt-2">
        <i class="bi bi-cash-coin">&nbsp;</i> {{'pagamento_devolucao.enviar_solicitacao' | translate}}
      </button>
    </div>

    <div *ngIf="!inscricao?.devolucaoConcluida && inscricao?.devolucaoSolicitada">
      <p class="small mt-2">
        {{'pagamento_devolucao.detalhes_solicitacao' | translate}}:
        <b
          >{{ inscricao?.devolucaoBanco }} - {{ inscricao?.devolucaoAgencia }} - {{ inscricao?.devolucaoConta }} -
          {{ inscricao?.devolucaoVariacao }}</b
        >
        <br /><a
          style="margin-left: 1px; margin-right: 1px"
          class="btn btn-secondary btn-sm"
          data-bs-toggle="collapse"
          attr.data-bs-target="#alterar-{{ inscricao?.id }}"
          aria-expanded="false"
          attr.aria-controls="alterar-{{ inscricao?.id }}"
          ><i class="bi bi-pencil-square">&nbsp;</i>{{'shared.alterar' | translate}}</a
        >
      </p>
      <div class="collapse mt-1" attr.id="alterar-{{ inscricao?.id }}">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-sm-3" style="margin-top: 0.5%">
              <div id="group_banco" class="input-group">
                <span class="input-group-text">{{'pagamento_shared.banco' | translate}}</span>
                <input class="form-control" required="" id="banco" name="banco" formControlName="banco" type="text" />
              </div>
            </div>
            <div class="col-sm-3" style="margin-top: 0.5%">
              <div id="group_agencia" class="input-group">
                <span class="input-group-text">{{'pagamento_shared.agencia' | translate}}</span>
                <input
                  class="form-control"
                  required=""
                  id="agencia"
                  name="agencia"
                  formControlName="agencia"
                  type="text" />
              </div>
            </div>
            <div class="col-sm-3" style="margin-top: 0.5%">
              <div id="group_conta" class="input-group">
                <span class="input-group-text">{{'pagamento_shared.cc' | translate}}</span>
                <input class="form-control" required="" id="conta" name="conta" formControlName="conta" type="text" />
              </div>
            </div>
            <div class="col-sm-3" style="margin-top: 0.5%">
              <div id="group_variacao" class="input-group">
                <span class="input-group-text">{{'pagamento_devolucao.variacao' | translate}}</span>
                <input
                  class="form-control"
                  required=""
                  id="variacao"
                  name="variacao"
                  formControlName="variacao"
                  type="text" />
              </div>
            </div>
          </div>
        </form>
        <button type="button" (click)="enviar()" class="btn btn-primary btn-flat mt-2">
          <i class="bi bi-cash-coin">&nbsp;</i> {{'pagamento_devolucao.enviar_solicitacao' | translate}}
        </button>
      </div>
    </div>
  </fieldset>
</div>
