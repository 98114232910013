/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, Input } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { ContaBancaria } from 'src/app/models/conta-bancaria'
import { Inscricao } from 'src/app/models/inscricao'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { ToastService } from '../../toast/services/toast.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-pagamento-devolucao',
  templateUrl: './pagamento-devolucao.component.html',
  styleUrls: ['./pagamento-devolucao.component.css'],
})
export class PagamentoDevolucaoComponent implements OnInit {
  @Input() inscricao?: Inscricao
  loading: boolean = false
  exibir: boolean = false
  i18n = {
    atencao: '',
    parabens: '',
    erroFormulario: '',
    sucessoSolicitacao: '',
    erroSolicitacao: ''
  }

  form = new FormGroup({
    banco: new FormControl('', Validators.required),
    agencia: new FormControl('', Validators.required),
    conta: new FormControl('', Validators.required),
    variacao: new FormControl('', Validators.required),
  })

  constructor(
    private inscricaoService: InscricaoService, 
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.carregarI18n();
    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });

    this.form.reset()
    if (this.inscricao?.devolucaoHabilitada && this.inscricao?.pago) {
      this.exibir = true
    }
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  enviar(): void {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroFormulario)
      return
    }

    let formulario = <ContaBancaria>this.form.value

    this.loading = true
    this.form.reset()
    if (this.inscricao) {
      this.inscricaoService.solicitarDevolucao(this.inscricao.id, formulario).subscribe(inscricao => {
        if (inscricao) {
          this.inscricao = inscricao
          this.toastService.showSuccessToast(this.i18n.parabens, this.i18n.sucessoSolicitacao)
        } else {
          this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroSolicitacao)
        }
        this.loading = false
      })
    }
  }

  private carregarI18n(): void {
    this.translateService.get([
      'shared.atencao',
      'shared.parabens',
      'shared.erro_formulario',
      'pagamento_devolucao.sucessoSolicitacao',
      'pagamento_devolucao.erroSolicitacao'
    ])
    .subscribe(traducoes =>{
      this.i18n.atencao = traducoes['shared.atencao']
      this.i18n.parabens = traducoes['shared.parabens']
      this.i18n.erroFormulario = traducoes['shared.erro_formulario']
      this.i18n.sucessoSolicitacao = traducoes['pagamento_devolucao.sucessoSolicitacao']
      this.i18n.erroSolicitacao = traducoes['pagamento_devolucao.erroSolicitacao']
    })
  }
}
