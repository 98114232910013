/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Input } from '@angular/core'
import { Andamento } from 'src/app/models/andamento'
import { DownloadService } from 'src/app/services/download.service'

@Component({
  selector: 'app-andamento',
  templateUrl: './andamento.component.html',
  styleUrls: ['./andamento.component.css'],
})
export class AndamentoComponent {
  @Input() andamentos?: Andamento[] = []
  @Input() id?: number = 0
  @Input() idConcurso?: number = 0
  @Input() collapsed: boolean = false
  tamanho: number = 0

  constructor(private downloadService: DownloadService) {}

  open(id: number, idConcurso: number): void {
    if (id == null) {
      this.downloadService.edital(idConcurso)
    } else {
      this.downloadService.andamento(id)
    }
  }

  ordenacaoAndamentos(andamentos: Andamento[] | undefined): Andamento[] | undefined {
    if (andamentos) {
      this.tamanho = andamentos.length
      for (let i = 0; i < this.tamanho; i++) {
        for (let j = 0; j < this.tamanho - i - 1; j++) {
          if (Date.parse(andamentos[j].data) > Date.parse(andamentos[j + 1].data)) {
            let temp = andamentos[j]
            andamentos[j] = andamentos[j + 1]
            andamentos[j + 1] = temp
          }
        }
      }
    }
    return andamentos
  }
}
