/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor() {}

  formatToUs(data: string): string {
    if (data == null || data == '' || data == undefined) {
      return ''
    }
    if (!data.includes('/') && data.includes('-')) {
      return data
    }
    const dataSplit = data.split('/')
    return dataSplit[2] + '-' + dataSplit[1] + '-' + dataSplit[0]
  }

  formatToBr(data: string): string {
    if (data == null || data == '' || data == undefined) {
      return ''
    }
    if (!data.includes('-') && data.includes('/')) {
      return data
    }
    const dataSplit = data.split('-')
    return dataSplit[2] + '/' + dataSplit[1] + '/' + dataSplit[0]
  }

  dateIsValid(dateStr: string): boolean {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/
    if (dateStr.match(regex) === null) {
      return false
    }
    const [day, month, year] = dateStr.split('/')
    const isoFormattedStr = `${year}-${month}-${day}`
    const date = new Date(isoFormattedStr)
    const timestamp = date.getTime()
    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
      return false
    }
    return date.toISOString().startsWith(isoFormattedStr)
  }
}
