/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { EMPTY, catchError as catchErrorRxJS, Observable, OperatorFunction } from 'rxjs'
import { ToastService } from '../modules/toast/services/toast.service'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  i18n = {
    atencao: '',
    falhaComunicacao: '',
    recursoIndisponivel: '',
    acessoNegado: ''
  }
  constructor(
    private router: Router, 
    private toastService: ToastService,
    private readonly translateService: TranslateService) 
    {
      this.carregarI18n();
      this.translateService.onLangChange.subscribe(() => {
        this.carregarI18n();
      });
    }

  catchError<T>(): OperatorFunction<T, T> {
    return catchErrorRxJS<T, Observable<T>>(err => {
      if (err.status == 0) {
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.falhaComunicacao)
      } else if (err.status == 404) {
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.recursoIndisponivel)
      } else if (err.status == 403) {
        localStorage.removeItem('sinsc-candidato-token')
        localStorage.removeItem('sinsc-candidato-cpf')
        localStorage.removeItem('sinsc-candidato-email')
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.acessoNegado)
        this.router.navigate(['sistema/entrar'])
      } else {
        this.toastService.showErrorToast(this.i18n.atencao, err.message)
      }
      return EMPTY
    })
  }

  private carregarI18n(): void {
    this.translateService.get([
      'shared.atencao',
      'error_handler.falha_comunicacao',
      'error_handler.recurso_indisponivel',
      'error_handler.acesso_negado'
    ])
    .subscribe(traducoes => {
      this.i18n.atencao = traducoes['shared.atencao']
      this.i18n.falhaComunicacao = traducoes['error_handler.falha_comunicacao']
      this.i18n.recursoIndisponivel = traducoes['error_handler.recurso_indisponivel'] 
      this.i18n.acessoNegado = traducoes['error_handler.acesso_negado']
    });
  }
}
