/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EMPTY, Observable } from 'rxjs'
import { UsuarioService } from '../services/usuario.service'

@Injectable({
  providedIn: 'root',
})
export class TokenHttpInterceptor implements HttpInterceptor {
  constructor(public usuarioService: UsuarioService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('sinsc-candidato-token')
    if (!token || req.url.includes('/publico/')) {
      return next.handle(req)
    }
    const expiry = JSON.parse(atob(token!.split('.')[1])).exp
    const now = Math.floor(new Date().getTime() / 1000)
    const expired: boolean = now >= expiry
    if (expired) {
      this.usuarioService.timeout()
      return EMPTY
    } else {
      return next.handle(
        req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token), withCredentials: false })
      )
    }
  }
}
