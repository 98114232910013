/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Input, OnInit } from '@angular/core'
import { Nota } from 'src/app/models/nota'

@Component({
  selector: 'app-candidato-inscricao-notas',
  templateUrl: './candidato-inscricao-notas.component.html',
  styleUrls: ['./candidato-inscricao-notas.component.css'],
})
export class CandidatoInscricaoNotasComponent implements OnInit {
  @Input() notas: Nota[] = []
  @Input() id?: number = 0
  @Input() collapsed: boolean = false

  ngOnInit(): void {
    for (let notaProva of this.notas) {
      notaProva.notaFormat = Number.isInteger(notaProva.casasDecimais)
        ? `1.${notaProva.casasDecimais}-${notaProva.casasDecimais}`
        : '1.2-2'

      const notasMap = notaProva.notas as any

      for (let criterio of notaProva.criterios) {
        if (!criterio.subCriterios || criterio.subCriterios.length == 0) {
          criterio.notas = notasMap[criterio.id]
          continue
        }

        let notasCriterio = [0, 0, 0]
        for (let subCriterio of criterio.subCriterios) {
          let notasSC = notasMap[subCriterio.id]
          subCriterio.notas = notasSC
          notasCriterio[0] += notasSC[0] ? notasSC[0] : 0
          notasCriterio[1] += notasSC[1] ? notasSC[1] : 0
          notasCriterio[2] += notasSC[2] ? notasSC[2] : 0
        }

        if (criterio.notaMaxima != null) {
          notasCriterio[0] = notasCriterio[0] < criterio.notaMaxima ? notasCriterio[0] : criterio.notaMaxima
          notasCriterio[1] = notasCriterio[1] < criterio.notaMaxima ? notasCriterio[1] : criterio.notaMaxima
          notasCriterio[2] = notasCriterio[2] < criterio.notaMaxima ? notasCriterio[2] : criterio.notaMaxima
        }

        criterio.notas = notasCriterio
      }
    }
  }
}
