<ng-container *ngFor="let criterio of criterios">
  <ng-container *ngIf="criterio.subCriterios.length > 0; then comSubCriterios; else semSubCriterios"></ng-container>

  <ng-template #comSubCriterios>
    <tr class="divisor-criterio">
      <td class="col-12 col-xs-9 col-sm-6 col-md-6 ps-3" colspan="4">
        <span style="line-height: 1.8em; font-size: 10pt"> {{ criterio.titulo }}</span>
      </td>
    </tr>
    <tr *ngFor="let subCriterio of criterio.subCriterios">
      <td class="col-12 col-xs-9 col-sm-6 col-md-6 ps-4">
        <span class="" style="line-height: 1.8em; font-size: 10pt">{{ subCriterio.titulo }}</span>
      </td>
      <td class="col-3 col-xs-1 col-sm-2 col-md-2 pe-2">
        <div class="nota-valor">{{subCriterio.notas?.[0] | number:'1.2-4'}}</div>
      </td>
      <td class="col-3 col-xs-1 col-sm-2 col-md-2 pe-2">
        <div class="nota-valor">{{subCriterio.notas?.[1] | number:'1.2-4'}}</div>
      </td>
      <td class="col-3 col-xs-1 col-sm-2 col-md-2 pe-2">
        <div class="nota-valor">{{subCriterio.notas?.[2] | number:'1.2-4'}}</div>
      </td>
    </tr>
    <tr class="total-criterio">
      <td class="col-12 col-xs-9 col-sm-6 col-md-6 ps-4">
        <span class="" style="line-height: 1.8em; font-size: 10pt"></span>
      </td>
      <td class="col-3 col-xs-1 col-sm-2 col-md-2 pe-2">
        <div class="nota-valor">{{criterio.notas?.[0] | number:'1.2-4'}}</div>
      </td>
      <td class="col-3 col-xs-1 col-sm-2 col-md-2 pe-2">
        <div class="nota-valor">{{criterio.notas?.[1] | number:'1.2-4'}}</div>
      </td>
      <td class="col-3 col-xs-1 col-sm-2 col-md-2 pe-2">
        <div class="nota-valor">{{criterio.notas?.[2] | number:'1.2-4'}}</div>
      </td>
    </tr>
  </ng-template>

  <ng-template #semSubCriterios>
    <tr>
      <td class="col-12 col-xs-9 col-sm-6 col-md-6 ps-3">
        <span style="line-height: 1.8em; font-size: 10pt"> {{ criterio.titulo }}</span>
      </td>
      <td class="col-3 col-xs-1 col-sm-2 col-md-2 pe-2">
        <div class="nota-valor">{{criterio.notas?.[0] | number:'1.2-4'}}</div>
      </td>
      <td class="col-3 col-xs-1 col-sm-2 col-md-2 pe-2">
        <div class="nota-valor">{{criterio.notas?.[1] | number:'1.2-4'}}</div>
      </td>
      <td class="col-3 col-xs-1 col-sm-2 col-md-2 pe-2">
        <div class="nota-valor">{{criterio.notas?.[2] | number:'1.2-4'}}</div>
      </td>
    </tr>
  </ng-template>
</ng-container>
