<div *ngIf="inscricao?.periodoInscricao">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto">{{'pagamento_comprovante.comprovante_pagamento' | translate}}</legend>
    <div *ngIf="exibirBotaoComprovante">
      <p class="small mt-1">
        <a class="btn btn-primary btn-sm" (click)="open()"><i class="bi bi-eye">&nbsp;</i>{{'pagamento_comprovante.visualizar_comprovante' | translate}}</a>
        <a
          class="btn btn-secondary btn-sm ms-2"
          data-bs-toggle="collapse"
          attr.data-bs-target="#alterarDeposito-{{ inscricao?.id }}"
          aria-expanded="true"
          attr.aria-controls="alterarDeposito-{{ inscricao?.id }}"
          ><i class="bi bi-pencil-square">&nbsp;</i>{{'shared.alterar' | translate}}</a
        >
      </p>
      <div class="collapse" attr.id="alterarDeposito-{{ inscricao?.id }}">
        <p class="small mt-2">{{'pagamento_comprovante.anexar_comprovante' | translate}}</p>
        <div class="input-group">
          <span class="input-group-text">
            <i class="bi bi-paperclip">&nbsp;</i>
          </span>
          <input
            id="{{ inscricao?.id }}"
            [ngClass]="inputClass"
            type="file"
            (change)="onFileSelected($event)"
            #fileUpload />
          <button type="button" (click)="enviar()" class="btn btn-primary btn-flat">{{'shared.enviar' | translate}}</button>
        </div>
      </div>
    </div>
    <div *ngIf="!exibirBotaoComprovante">
      <div>
        <p class="small mt-2">{{'pagamento_comprovante.anexar_comprovante' | translate}}</p>
        <div class="input-group">
          <span class="input-group-text">
            <i class="bi bi-paperclip">&nbsp;</i>
          </span>
          <input
            id="{{ inscricao?.id }}"
            [ngClass]="inputClass"
            type="file"
            (change)="onFileSelected($event)"
            #fileUpload />
          <button type="button" (click)="enviar()" class="btn btn-primary btn-flat">{{'shared.enviar' | translate}}</button>
        </div>
      </div>
    </div>
  </fieldset>
</div>
