/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

export enum OrgaoExpedicaoRg {
  SSP = 'SSP - Secretaria de Segurança Pública',
  PM = 'PM - Polícia Militar',
  PC = 'PC - Policia Civil',
  CNT = 'CNT - Carteira Nacional de Habilitação',
  DIC = 'DIC - Diretoria de Identificação Civil',
  CTPS = 'CTPS - Carteira de Trabaho e Previdência Social',
  FGTS = 'FGTS - Fundo de Garantia do Tempo de Serviço',
  IFP = 'IFP - Instituto Félix Pacheco',
  IPF = 'IPF - Instituto Pereira Faustino',
  IML = 'IML - Instituto Médico-Legal',
  MTE = 'MTE - Ministério do Trabalho e Emprego',
  MMA = 'MMA - Ministério da Marinha',
  MAE = 'MAE - Ministério da Aeronáutica',
  MEX = 'MEX - Ministério do Exército',
  POF = 'POF - Polícia Federal',
  POM = 'POM - Polícia Militar',
  SES = 'SES - Carteira de Estrangeiro',
  SJS = 'SJS - Secretaria da Justiça e Segurança',
  SJTS = 'SJTS - Secretaria da Justiça do Trabalho e Segurança',
  ZZZ = 'ZZZ - Outros (inclusive exterior)',
}
