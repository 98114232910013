/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { PixPreview } from '../models/pix-preview'

@Injectable({
  providedIn: 'root',
})
export class PixModalService {
  pixModalEventsArquivo: Observable<PixPreview>
  private _pixModalEventsArquivo = new Subject<PixPreview>()

  constructor() {
    this.pixModalEventsArquivo = this._pixModalEventsArquivo.asObservable()
  }

  showPreview(url: string, title: string, text: string): void {
    this._pixModalEventsArquivo.next({
      url,
      title,
      text,
    })
  }
}
