<section>
  <h2 style="display: none">{{'senha_redefinir.redefinir_senha' | translate}}</h2>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active">{{'senha_redefinir.redefinir_senha' | translate}}</li>
      </ol>
    </nav>
    <form [formGroup]="form">
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">{{'senha_redefinir.senha' | translate}}</legend>
        <div class="mb-3 mt-3">
          <div class="input-group">
            <label class="input-group-text">{{'senha_shared.nova_senha' | translate}}</label>
            <input class="form-control" formControlName="novaSenha" type="password" />
          </div>
          <div id="ttSenha" class="form-text">
            {{'senha_shared.senha_deve_conter' | translate}}
          </div>
        </div>
        <div>
          <div class="input-group">
            <label class="input-group-text">{{'shared.confirmacao' | translate}}</label>
            <input class="form-control" formControlName="novaSenhaConfirmacao" type="password" />
          </div>
        </div>
      </fieldset>
      <div class="row mt-3">
        <div class="col-12 d-flex flex-row-reverse">
          <a (click)="alterar()" class="btn btn-primary btn-sm"
            ><i class="bi bi-check-circle" aria-hidden="true">&nbsp;</i> <span>{{'shared.enviar' | translate}}</span></a
          >
        </div>
      </div>
    </form>
  </div>
</section>
