<div class="spinner-border" role="status" *ngIf="loading">
  <span class="visually-hidden">{{'shared.carregando' | translate}}</span>
</div>

<div *ngIf="!loading && exibir && !inscricao?.depositoAnexo">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto">{{'pagamento_deposito.pagamento_deposito' | translate}}</legend>

    <div *ngIf="!inscricao?.depositoAnexo">
      <p class="small mt-2">
        {{'pagamento_deposito.prezado_candidato' | translate}}:
      </p>
      <ul class="mb-0">
        <li>
          {{'pagamento_shared.banco' | translate}}: <b>{{ inscricao?.depositoBanco }}</b>
        </li>
        <li>
          {{'pagamento_shared.agencia' | translate}}: <b>{{ inscricao?.depositoAgencia }}</b>
        </li>
        <li>
          {{'pagamento_shared.cc' | translate}}: <b>{{ inscricao?.depositoConta }}</b>
        </li>
        <li>
          {{'pagamento_shared.valor' | translate}}: <b>R$ {{ inscricao?.valor }}</b>
        </li>
      </ul>
    </div>
  </fieldset>
</div>
<div *ngIf="aguardandoAprovacaoReducao">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto mb-1">{{'pagamento_deposito.pagamento_deposito' | translate}}</legend>
    <ul class="mb-0">
      <li class="text-danger">{{'pagamento_shared.aguardando_decisao_reducao' | translate}}</li>
    </ul>
  </fieldset>
</div>
