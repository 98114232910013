/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Input, OnInit } from '@angular/core'
import { PixModalService } from '../services/pix-modal.service'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { PixPreview } from '../models/pix-preview'

@Component({
  selector: 'app-pix-modal',
  templateUrl: './pix-modal.component.html',
  styleUrls: ['./pix-modal.component.css'],
})
export class PixModalComponent implements OnInit {
  pixPreview?: PixPreview
  exibir: boolean = false
  @Input() trustedUrl!: SafeUrl
  windowHeight: number = 400
  exibeMensagem: boolean = false
  codigoCopiaECola: string = ''

  constructor(private sanitizer: DomSanitizer, private pixModalService: PixModalService) {}

  ngOnInit(): void {
    this.windowHeight = window.innerHeight - 200
    this.subscribeToModal()
  }

  subscribeToModal() {
    this.pixModalService.pixModalEventsArquivo.subscribe(pixPreview => {
      this.pixPreview = pixPreview
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pixPreview.url)
      this.toggle()
      this.codigoCopiaECola = pixPreview.text
    })
  }

  toggle(): void {
    this.exibir = !this.exibir
  }

  copiaECola() {
    navigator.clipboard.writeText(this.codigoCopiaECola)
    this.exibeMensagem = true
  }
}
