/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

export enum GrauEscolaridade {
  ENSINO_FUNDAMENTAL = 'Ensino Fundamental',
  ENSINO_MEDIO = 'Ensino Médio',
  ENSINO_SUPERIOR = 'Ensino Superior',
  ESPECIALIZACAO = 'Especialização',
  MESTRADO = 'Mestrado',
  DOUTORADO = 'Doutorado',
  LIVRE_DOCENTE = 'Livre-docente',
}
