<div [class.collapse]="!collapsed" id="documentos{{ id }}" *ngIf="documentos?.length">
  <div class="mb-3">
    <fieldset class="border rounded-3 p-2 mt-0">
      <legend class="w-auto">{{'documentos.documentos' | translate}}</legend>
      <div class="mt-3 mb-2">
        <div class="row">
          <div
            class="col-xs-12 col-sm-12 col-md-12 course-tabs-content"
            id="course-tabs-content-{{ id }}"
            style="margin-top: -1%; margin-bottom: -2%">
            <article id="UNESP-{{ id }}" class="course-tab-item" style="display: block">
              <div class="panel panel-default">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr *ngFor="let documento of documentos">
                      <td>
                        <div class="row">
                          <div class="col-xs-12 col-sm-8 col-md-9">
                            <span
                              class="course-tab-item-title"
                              style="line-height: 1.8em; font-size: 10pt; font-weight: bold">
                              {{ tipoDocumentoStr(documento.tipoDocumento) }}
                              <span class="small"
                                ><br />
                                <span>{{'documentos.tamanho' | translate}}: {{ documento.tamanho / (1024 * 1024) | number : '1.2-2' }} MB </span>
                              </span>
                            </span>
                          </div>
                          <div class="col-xs-12 col-sm-4 col-md-3 d-flex flex-row-reverse">
                            <a
                              class="btn btn-outline-secondary btn-sm"
                              title="{{'documentos.acessar_documento' | translate}}"
                              style="margin-left: 10px; max-height: 32px"
                              (click)="open(documento)">
                              <i class="bi bi-file-earmark-pdf" aria-hidden="true"></i
                            ></a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </article>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</div>
