<ng-container *ngIf="exibir">
  <div class="simple-modal">
    <div class="simple-modal-dialog modal-lg">
      <div class="simple-modal-content">
        <div class="simple-modal-header">
          <h5 class="simple-modal-title">
            {{ boletoPreview?.title }}
          </h5>
        </div>
        <div class="simple-modal-body">
          <embed [src]="trustedUrl" type="application/pdf" width="100%" height="{{ windowHeight }}" />
        </div>
      </div>
    </div>
  </div>
  <div (click)="toggle()" class="overlay"></div>
</ng-container>
