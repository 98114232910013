/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { identity, Observable } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  private baseUrl: string

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {
    this.baseUrl = environment.baseUrlApi
  }

  private downloadBoleto(response: any, id: number, tipo: string): void {
    let binaryData = []
    binaryData.push(response.body)
    let downloadLink = document.createElement('a')
    downloadLink.setAttribute('download', `boleto-${id}.pdf`)
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: response.type }))
    document.body.appendChild(downloadLink)
    downloadLink.click()
    downloadLink.remove()
  }

  private downloadPix(response: any, id: number, tipo: string): void {
    let binaryData = []
    binaryData.push(response.body)
    let downloadLink = document.createElement('a')
    downloadLink.setAttribute('download', `pix-${id}.png`)
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: response.type }))
    document.body.appendChild(downloadLink)
    downloadLink.click()
    downloadLink.remove()
  }

  private download(response: any): void {
    let contentDisposition = response.headers.get('Content-Disposition')
    var filename = contentDisposition.split(';')[1].split('=')[1].trim().replace(/\"/g, '')
    let binaryData = []
    binaryData.push(response.body)
    let downloadLink = document.createElement('a')
    downloadLink.setAttribute('download', filename)
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: response.type }))
    document.body.appendChild(downloadLink)
    downloadLink.click()
    downloadLink.remove()
  }

  private request(url: string): Observable<any> {
    return this.http
      .get<any>(url, { responseType: 'blob' as 'json', observe: 'response' })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  comprovantePagamento(id: number): void {
    let url = `${this.baseUrl}/privado/candidato/download/comprovante-pagamento/${id}`
    this.request(url).subscribe((response: any) => this.download(response))
  }

  comprovanteEstudante(id: number): void {
    let url = `${this.baseUrl}/privado/candidato/download/comprovante-estudante/${id}`
    this.request(url).subscribe((response: any) => this.download(response))
  }

  comprovanteRenda(id: number): void {
    let url = `${this.baseUrl}/privado/candidato/download/comprovante-renda/${id}`
    this.request(url).subscribe((response: any) => this.download(response))
  }

  deficienciaLaudoMedico(id: number): void {
    let url = `${this.baseUrl}/privado/candidato/download/deficiencia-laudo-medico/${id}`
    this.request(url).subscribe((response: any) => this.download(response))
  }

  recurso(id: number): void {
    let url = `${this.baseUrl}/privado/candidato/download/recurso/${id}`
    this.request(url).subscribe((response: any) => this.download(response))
  }

  anexoParecer(id: number): void {
    let url = `${this.baseUrl}/privado/candidato/download/parecer/${id}`
    this.request(url).subscribe((response: any) => this.download(response))
  }

  complemento(id: number): void {
    let url = `${this.baseUrl}/privado/candidato/download/complemento/${id}`
    this.request(url).subscribe((response: any) => this.download(response))
  }

  complementoAcessoDireto(id: number, filename: string): void {
    let url = `${this.baseUrl}/privado/candidato/download/complemento/${id}/${filename}`
    this.request(url).subscribe((response: any) => this.download(response))
  }

  edital(id: number): void {
    let url = `${this.baseUrl}/publico/download/edital/${id}`
    this.request(url).subscribe((response: any) => this.download(response))
  }

  andamento(id: number): void {
    let url = `${this.baseUrl}/publico/download/andamento/${id}`
    this.request(url).subscribe((response: any) => this.download(response))
  }

  boleto(id: number): void {
    let tipo = 'BOLETO'
    let url = `${this.baseUrl}/privado/candidato/inscricao/${id}/tipo-arquivo-pagamento`
    this.request(url).subscribe((response: any) => this.downloadBoleto(response, id, tipo))
  }

  pix(id: number): void {
    let tipo = 'PIX'
    let url = `${this.baseUrl}/privado/candidato/inscricao/${id}/tipo-arquivo-pagamento`
    this.request(url).subscribe((response: any) => this.downloadPix(response, id, tipo))
  }

  inscricaoDocumento(id: number): void {
    let url = `${this.baseUrl}/privado/candidato/download/inscricao-documento/${id}`
    this.request(url).subscribe((response: any) => this.download(response))
  }
}
