/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { ConcursoService } from 'src/app/services/concurso.service'
import { ContatoService } from 'src/app/services/contato.service'
import { UsuarioService } from 'src/app/services/usuario.service'
import { ToastService } from '../../toast/services/toast.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-contator',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css'],
})
export class ContatoComponent implements OnInit {
  concursoTitulo: string = ''
  i18n = {
    sucessoEnviarContato: '',
    atencao: '',
    erroEnviarContato: '',
    erroFormulario: ''
  };

  form = new FormGroup({
    idConcurso: new FormControl('', Validators.required),
    nome: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    mensagem: new FormControl('', Validators.required),
  })

  constructor(
    private route: ActivatedRoute,
    private concursoService: ConcursoService,
    private usuarioService: UsuarioService,
    private contatoService: ContatoService,
    private toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.carregarI18n();
    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });
    
    this.route.params.subscribe(params => {
      this.form.get('idConcurso')?.setValue(params['idConcurso'])
      this.concursoService.get(params['idConcurso']).subscribe(concurso => {
        this.concursoTitulo = concurso.titulo
        this.usuarioService.get().subscribe(usuario => {
          this.form.get('email')?.setValue(usuario.email)
        })
      })
    })
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  enviar() {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroFormulario)
      return
    }

    var jsonData = {
      idConcurso: this.form.get('idConcurso')?.value,
      nome: this.form.get('nome')?.value,
      email: this.form.get('email')?.value,
      mensagem: this.form.get('mensagem')?.value,
    }
    this.contatoService.enviar(jsonData).subscribe(resultado => {
      if (resultado == 'done') {
        this.toastService.showSuccessToast(this.i18n.atencao, this.i18n.sucessoEnviarContato)
      } else {
        this.toastService.showErrorToast(
          this.i18n.atencao,
          this.i18n.sucessoEnviarContato
        )
      }
      this.form.reset()
    })
  }

  private carregarI18n(): void {
    this.translateService.get([
      'contato.sucesso_enviar', 'contato.erro_enviar', 'shared.atencao', 'shared.erro_formulario'
    ])
    .subscribe(traducoes => {
      this.i18n.sucessoEnviarContato = traducoes['contato.mensagem_sucesso']
      this.i18n.erroEnviarContato = traducoes['contato.erro_enviar']
      this.i18n.atencao = traducoes['shared.atencao'];
      this.i18n.erroFormulario = traducoes['shared.erro_formulario']
    })
  }
}
