/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { UsuarioService } from 'src/app/services/usuario.service'
import { ToastService } from '../../toast/services/toast.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-senha-redefinir',
  templateUrl: './senha-redefinir.component.html',
  styleUrls: ['./senha-redefinir.component.css'],
})
export class SenhaRedefinirComponent implements OnInit {
  codigo: string = ''
  senhaPattern: string = '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d).{8,}$'

  form = new FormGroup({
    novaSenha: new FormControl('', [Validators.required, Validators.pattern(this.senhaPattern)]),
    novaSenhaConfirmacao: new FormControl('', [Validators.required, Validators.pattern(this.senhaPattern)]),
  })

  i18n = {
    atencao: '',
    erroFormulario: '',
    senhaNaoConfere: '',
    sucessoAlteracao: '',
    erroAlteracao: '',
    codigoInvalido: ''
  };

  constructor(
    private usuarioService: UsuarioService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.carregarI18n();
    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });
    
    this.route.params.subscribe(params => {
      this.codigo = params['codigo']
    })
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  alterar() {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroFormulario)
      return
    }

    let novaSenha = this.form.get('novaSenha')?.value ?? ''
    let novaSenhaConfirmacao = this.form.get('novaSenhaConfirmacao')?.value ?? ''
    if (novaSenha !== novaSenhaConfirmacao) {
      this.toastService.showErrorToast(this.i18n.atencao, this.i18n.senhaNaoConfere)
    } else {
      var jsonData = {
        codigo: this.codigo,
        senha: this.form.get('novaSenha')?.value,
      }
      this.usuarioService.senhaRedefinir(jsonData).subscribe(resultado => {
        if (resultado == 'updated') {
          this.toastService.showSuccessToast(this.i18n.atencao, this.i18n.sucessoAlteracao)
          this.form.reset()
          this.router.navigate(['/sistema/entrar'])
        } else if (resultado == 'not-found') {
          this.toastService.showErrorToast(this.i18n.atencao, this.i18n.codigoInvalido)
          this.router.navigate(['/'])
        } else {
          this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroAlteracao)
        }
      })
    }
  }

  private carregarI18n(): void {
    this.translateService.get([
      'shared.atencao',
      'shared.erro_formulario',
      'senha_shared.senha_nao_confere', 
      'senha_shared.sucesso_alteracao', 
      'senha_shared.erro_alteracao'])
      .subscribe(traducoes =>{
        this.i18n.atencao = traducoes['shared.atencao']
        this.i18n.erroFormulario = traducoes['shared.erro_formulario']
        this.i18n.senhaNaoConfere = traducoes['senha_shared.senha_nao_confere']
        this.i18n.sucessoAlteracao = traducoes['senha_shared.sucesso_alteracao']
        this.i18n.erroAlteracao = traducoes['senha_shared.erro_alteracao']
        this.i18n.codigoInvalido = traducoes['senha_redefinir.codigo_invalido']
    })
  }
}
