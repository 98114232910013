/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Input, OnInit } from '@angular/core'
import { Inscricao } from 'src/app/models/inscricao'
import { DownloadService } from 'src/app/services/download.service'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { ToastService } from '../../toast/services/toast.service'

@Component({
  selector: 'app-pagamento-deposito',
  templateUrl: './pagamento-deposito.component.html',
  styleUrls: ['./pagamento-deposito.component.css'],
})
export class PagamentoDepositoComponent implements OnInit {
  @Input() inscricao?: Inscricao
  loading: boolean = false
  file?: File | null
  inputClass: string = 'form-control'
  exibir: boolean = false
  aguardandoAprovacaoReducao: boolean = false

  constructor(
    private inscricaoService: InscricaoService,
    private downloadService: DownloadService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    if (this.inscricao?.periodoInscricao && this.inscricao?.deposito == 'SIM') {
      if (this.inscricao?.reducao && !this.inscricao.reducaoConcluida) {
        this.aguardandoAprovacaoReducao = true
      }
      if (this.inscricao?.periodoInscricao && !this.inscricao?.pago && !this.aguardandoAprovacaoReducao) {
        this.exibir = true
      }
    }
  }
}
