/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { Concurso } from 'src/app/models/concurso'
import { ConcursoService } from 'src/app/services/concurso.service'
import { PaginationBaseComponent } from '../../pagination/components/pagination-base.component'

@Component({
  selector: 'app-lista-inscricao-proxima',
  templateUrl: './lista-inscricao-proxima.component.html',
  styleUrls: ['./lista-inscricao-proxima.component.css'],
})
export class ListaInscricaoProximaComponent extends PaginationBaseComponent implements OnInit {
  concursos: Concurso[] = []
  input: string = ''
  private _search: string = ''
  private _searchClicked: boolean = true

  constructor(private concursoService: ConcursoService) {
    super()
  }

  ngOnInit(): void {
    this.getData()
  }

  search(): void {
    this._search = this.input.trim().toLowerCase()
    this._searchClicked = true
    this.getData()
  }

  override getData(): void {
    let filter: any =
      this._search === ''
        ? { page: this.current - 1, size: this.perPage }
        : this._searchClicked
        ? { search: this._search }
        : { page: this.current - 1, size: this.perPage, search: this._search }
    this._searchClicked = false
    this.concursoService.inscricoesProximas(filter).subscribe(data => {
      this.concursos = data['content']
      this.totalPages = data['totalPages']
      this.totalElements = data['totalElements']
    })
  }
}
