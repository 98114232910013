import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ErrorHandlerService } from "./error-handler.service";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EnderecoSensedia } from "../models/endereco-sensedia";


@Injectable({
    providedIn: 'root',
  })
  export class SensediaCepService {
    constructor(private readonly http: HttpClient, private readonly errorHandlerService: ErrorHandlerService) {}

    private readonly sensediaUrl = environment.sensediaUrl;
    private readonly sensediaClientId = environment.sensediaClientId
  
    consultaCEP(cep: string): Observable<EnderecoSensedia> {
      
      const headers = new HttpHeaders({
        'client_id': this.sensediaClientId
      })

      return this.http
        .get<EnderecoSensedia[]>(`${this.sensediaUrl}/tpub/v1/cep/${cep}`, {headers})
        .pipe(
          map((response: EnderecoSensedia[]) => {
            const endereco = response[0];
            return {
              cepatu: endereco.cepatu.trim(),
              mlogr: endereco.mlogr.trim(),
              tlogr: endereco.tlogr.trim(),
              mbair1: endereco.mbair1.trim(),
              mlocal: endereco.mlocal.trim(),
              suf: endereco.suf.trim()
            };
          })
        );
    }
  }