<div class="spinner-border" role="status" *ngIf="loading">
  <span class="visually-hidden">{{'shared.carregando' | translate}}</span>
</div>

<div *ngIf="!loading && exibir">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto">{{'pagamento_pix.pagamento_pix' | translate}}</legend>
    <div>
      <p class="small mt-2">{{'pagamento_pix.clique_gerar_qrcode' | translate}}</p>
      <button type="button" (click)="exibirQrCode()" class="botao_pix btn btn-secondary btn-sm">
        <i class="bi bi-qr-code-scan" aria-hidden="true">&nbsp;</i> {{'pagamento_pix.gerar_qrcode' | translate}}
      </button>
    </div>
  </fieldset>
</div>

<div *ngIf="aguardandoAprovacaoReducao">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto mb-1">{{'pagamento_shared.pagamento' | translate}}</legend>
    <ul class="mb-0">
      <li class="text-danger">{{'pagamento_shared.aguardando_decisao_reducao' | translate}}</li>
    </ul>
  </fieldset>
</div>

<div *ngIf="problemaNoPix">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto mb-1">{{'pagamento_shared.pagamento' | translate}}</legend>
    <ul class="mb-0">
      <li class="text-danger">{{'pagamento_pix.pix_não_disponivel' | translate}}</li>
    </ul>
  </fieldset>
</div>
