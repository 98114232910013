<nav class="navbar navbar-expand-lg navbar-dark bg-primary mb-3">
  <h6 style="display: none">MENU</h6>
  <div class="container">
    <a class="navbar-brand" routerLink="/">
      <div class="inline-grid">
        <img src="assets/image/unicamp-white-nome-em-baixo.png" width="40" height="40" alt="Unicamp" title="Unicamp" />
      </div>
    </a>
    <a class="navbar-brand" routerLink="/">
      <div class="inline-grid">
        <img src="assets/image/DETIC-branco-sem-descricao.png" width="105" height="30" propo alt="Detic" title="Detic" />
      </div>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target=".navbar-collapse"
      aria-controls="navbar"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon">&nbsp;</span>
    </button>
    <div class="collapse navbar-collapse" id="navbar">
      <ul class="navbar-nav mr-auto mb-2 mb-md-0">
        <li class="nav-item">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show"
            routerLink="/concurso/inscricao-aberta"
            title="{{'shared.concursos' | translate}}"
            >{{'shared.concursos' | translate}} v{{ version }}</a
          >
        </li>
      </ul>
      <ul class="navbar-nav ms-auto mb-2 mb-md-0">
        <li class="nav-item" *ngIf="!usuarioService.isLoggedIn()">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show"
            routerLink="/sistema/entrar"
            title="{{'shared.area_do_candidato' | translate}}"
            >{{'shared.area_do_candidato' | translate}}</a
          >
        </li>
        <li class="nav-item dropdown" *ngIf="usuarioService.isLoggedIn()">
          <a
            class="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            title="{{'shared.area_do_candidato' | translate}}"
            >{{'shared.area_do_candidato' | translate}}</a
          >
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li>
              <a
                class="dropdown-item"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                routerLink="/candidato/inscricoes"
                title="{{'header.minhas_inscricoes' | translate}}"
                >{{'header.inscricoes' | translate}}</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                routerLink="/candidato/recursos"
                title="{{'header.meus_recursos'}}"
                >{{'recurso_shared.recursos' | translate}}</a
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <a
                class="dropdown-item"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                routerLink="/candidato/senha-alterar"
                title="{{'header.alterar_senha' | translate}}"
                >{{'header.alterar_senha' | translate}}</a
              >
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show"
            routerLink="/sistema/manual"
            title="{{'header.manual' | translate}}"
            >{{'header.manual' | translate}}</a
          >
        </li>
        <li class="nav-item" *ngIf="usuarioService.isLoggedIn()">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show"
            (click)="usuarioService.logout()"
            title="{{'header.sair' | translate}}"
            >{{'header.sair' | translate}}</a
          >
        </li>
        <li class="nav-item d-flex align-items-center">
          <button
            class="btn btn-link nav-link p-0 mx-1"
            [class.selected]="selectedLanguage === 'pt'"
            (click)="setLanguage('pt')"
            title="Mudar idioma para Português">
            <img src="assets/image/bandeira-brasil.png" alt="Mudar para Português" class="rounded-flag" />
          </button>
        </li>
        <li class="nav-item d-flex align-items-center">
          <button
            class="btn btn-link nav-link p-0 mx-1"
            [class.selected]="selectedLanguage === 'en'"
            (click)="setLanguage('en')"
            title="Change language to English">
            <img src="assets/image/bandeira-eua.png" alt="Change to English" class="rounded-flag" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
