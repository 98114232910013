<div class="spinner-border" role="status" *ngIf="loading">
  <span class="visually-hidden">{{'shared.carregando' | translate}}</span>
</div>

<div class="row" *ngIf="periodoPermitido && !loading">
  <div class="col-sm-12" style="margin-top: 10px">
    <div id="group_{{ inscricaoComplemento?.id }}" class="input-group">
      <span [ngClass]="spanClass">{{ inscricaoComplemento?.requerido ? ('formulario_shared.obrigatorio' | translate) : ('formulario_shared.opcional' | translate) }}</span>
      <div class="input-group-text">
        <input
          type="checkbox"
          class="form-control form-check-input"
          id="{{ inscricaoComplemento?.id }}"
          [(ngModel)]="input"
          (change)="enviar()" />
      </div>
      <p class="form-control" style="height: 100%">
        {{ inscricaoComplemento?.grupo }}<br />{{ inscricaoComplemento?.explicacao }}
      </p>
    </div>
  </div>
</div>

<div class="row" *ngIf="!periodoPermitido && !loading">
  <div class="col-sm-12" style="margin-top: 10px">
    <div id="group_{{ inscricaoComplemento?.id }}" class="input-group">
      <span [ngClass]="spanClass">{{ inscricaoComplemento?.requerido ? ('formulario_shared.obrigatorio' | translate) : ('formulario_shared.opcional' | translate) }}</span>
      <div class="input-group-text">
        {{ input === 'on' ? ('shared.sim' | translate) : ('formulario_shared.nao_informado' | translate) }}
      </div>
      <p class="form-control" style="height: 100%">
        {{ inscricaoComplemento?.grupo }}<br />{{ inscricaoComplemento?.explicacao }}
      </p>
    </div>
  </div>
</div>

<hr style="border: 0; border-top: dotted 1px #000" />
