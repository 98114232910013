/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, Input } from '@angular/core'
import { Inscricao } from 'src/app/models/inscricao'
import { DownloadService } from 'src/app/services/download.service'
import { ToastService } from '../../toast/services/toast.service'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-pagamento-comprovante',
  templateUrl: './pagamento-comprovante.component.html',
  styleUrls: ['./pagamento-comprovante.component.css'],
})
export class PagamentoComprovanteComponent implements OnInit {
  @Input() inscricao?: Inscricao
  loading: boolean = false
  file?: File | null
  inputClass: string = 'form-control'
  exibir: boolean = false
  aguardandoAprovacaoReducao: boolean = false
  exibirBotaoComprovante: boolean = false
  i18n = {
    atencao: '',
    parabens: '',
    erroTipoArquivo: '',
    erroTamArquivo: '',
    sucessoEnvio: '',
    erroEnvio: ''
  }

  constructor(
    private inscricaoService: InscricaoService,
    private downloadService: DownloadService,
    private toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.carregarI18n();
    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });

    if (this.inscricao?.periodoInscricao) {
      if (this.inscricao?.depositoAnexo !== null && this.inscricao?.depositoAnexo !== '') {
        this.exibirBotaoComprovante = true
      } else {
        this.exibirBotaoComprovante = false
      }
      if (this.inscricao?.reducao && !this.inscricao.reducaoConcluida) {
        this.aguardandoAprovacaoReducao = true
      } else if (this.inscricao?.periodoInscricao && !this.aguardandoAprovacaoReducao) {
        this.exibir = true
      }
    }
  }

  onFileSelected(event: any): void {
    this.file = event.target.files[0]
    if (this.file) {
      if (this.file.type !== 'application/pdf' && this.file.type !== 'image/png' && this.file.type !== 'image/jpeg') {
        this.inputClass = 'form-control is-invalid'
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroTipoArquivo)
        this.file = null
        return
      }
      if (this.file.size > 512000000) {
        this.inputClass = 'form-control is-invalid'
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroTamArquivo)
        this.file = null
        return
      }
      this.inputClass = 'form-control is-valid'
    }
  }

  enviar(): void {
    if (this.file) {
      const formData = new FormData()
      formData.append('file', this.file)
      this.loading = true
      if (this.inscricao) {
        this.inscricaoService.comprovantePagamento(this.inscricao.id, formData).subscribe(inscricao => {
          if (inscricao) {
            this.inscricao = inscricao
            this.inputClass = 'form-control'
            this.toastService.showSuccessToast(this.i18n.parabens, this.i18n.sucessoEnvio)
          } else {
            this.inputClass = 'form-control is-valid'
            this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroEnvio)
          }
          this.loading = false
        })
      }
    }
  }

  open(): void {
    this.downloadService.comprovantePagamento(this.inscricao?.id ?? 0)
  }

  private carregarI18n(): void {
    this.translateService.get([
      'shared.atencao',
      'shared.parabens',
      'arquivo_shared.arquivo_tipo',
      'arquivo_shared.arquivo_excedeu',
      'arquivo_shared.sucesso_envio',
      'arquivo_shared.erro_envio',
    ])
    .subscribe(traducoes =>{
      this.i18n.atencao = traducoes['shared.atencao']
      this.i18n.parabens = traducoes['shared.parabens']
      this.i18n.erroTipoArquivo = traducoes['arquivo_shared.arquivo_tipo']
      this.i18n.erroTamArquivo = traducoes['arquivo_shared.arquivo_excedeu']
      this.i18n.sucessoEnvio = traducoes['arquivo_shared.sucesso_envio']
      this.i18n.erroEnvio = traducoes['arquivo_shared.erro_envio']
    })
  }
}
