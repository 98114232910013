<div *ngIf="showDivComplemento">
  <div class="spinner-border" role="status" *ngIf="loading">
    <span class="visually-hidden">{{'shared.carregando' | translate}}</span>
  </div>
  <div *ngIf="inscricaoComplementoSituacao?.hasComplementoInscricao">
    <div class="alert alert-warning mb-0" *ngIf="!loading && tipoFase1 === 'PENDENTE'">
      <p class="small">
        {{'candidato_inscricao_shared.documentacao_complementar' | translate}}: {{ inscricaoComplementoSituacao?.totalObrigatorioPendente }} {{'formulario_situacao.items_obrigatorios_pendentes' | translate}}
      </p>
      <p class="small" *ngIf="opcionalPendente">
        {{'formulario_shared.documentacao_complementar' | translate}}:
        {{ inscricaoComplementoSituacao?.quantidadeMinimaOpcionalRequerido }} {{'formulario_situacao.items_nao_obrigatorios_pendentes' | translate}}
      </p>
    </div>
    <div class="alert alert-danger mb-0" *ngIf="!loading && tipoFase1 === 'INCOMPLETA'">
      <p class="small">
        {{'formulario_shared.documentacao_complementar' | translate}}: {{ inscricaoComplementoSituacao?.totalObrigatorioPendente }} {{'formulario_situacao.items_obrigatorios_nao_enviados' | translate}}
      </p>
      <p class="small" *ngIf="opcionalPendente">
        {{'formulario_shared.documentacao_complementar' | translate}}:
        {{ inscricaoComplementoSituacao?.quantidadeMinimaOpcionalRequerido }} {{'formulario_situacao.items_nao_obrigatorios_nao_enviados' | translate}}
      </p>
    </div>
    <div class="alert alert-success mb-0" *ngIf="!loading && tipoFase1 === 'COMPLETA'">
      <p class="small">{{'formulario_shared.documentacao_complementar' | translate}}: {{'formulario_situacao.envio_completo' | translate}}.</p>
    </div>
  </div>

  <div *ngIf="inscricaoComplementoSituacao?.hasComplementoSegundaFase">
    <div class="alert alert-warning mb-0 mt-3" *ngIf="!loading && tipoFase2 === 'PENDENTE'">
      <p class="small">
        {{'formulario_shared.documentacao_complementar_segunda_fase' | translate}}:
        {{ inscricaoComplementoSituacao?.totalObrigatorioPendenteSegundaFase }} {{'formulario_situacao.items_obrigatorios_pendentes' | translate}}<br />
        <i>({{'formulario_situacao.insercao_ate_tres_dias' | translate}})</i>
      </p>
    </div>
    <div class="alert alert-danger mb-0 mt-3" *ngIf="!loading && tipoFase2 === 'INCOMPLETA'">
      <p class="small">
        {{'formulario_shared.documentacao_complementar_segunda_fase' | translate}}:
        {{ inscricaoComplementoSituacao?.totalObrigatorioPendenteSegundaFase }} {{'formulario_situacao.items_obrigatorios_nao_enviados' | translate}}
      </p>
    </div>
    <div class="alert alert-success mb-0 mt-3" *ngIf="!loading && tipoFase2 === 'COMPLETA'">
      <p class="small">{{'formulario_shared.documentacao_complementar_segunda_fase' | translate}}: {{'formulario_situacao.envio_completo' | translate}}</p>
    </div>
  </div>
</div>
