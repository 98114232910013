/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgSelectModule } from '@ng-select/ng-select'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { DatePipe, registerLocaleData } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from 'src/environments/environment'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'

import localePt from '@angular/common/locales/pt'
registerLocaleData(localePt, 'pt')

import { HeaderComponent } from './modules/sistema/header/header.component'
import { FooterComponent } from './modules/sistema/footer/footer.component'
import { PageNotFoundComponent } from './modules/sistema/page-not-found/page-not-found.component'
import { ToastComponent } from './modules/toast/compoments/toast/toast.component'
import { ToasterComponent } from './modules/toast/compoments/toaster/toaster.component'
import { ListaInscricaoAbertaComponent } from './modules/concurso/lista-inscricao-aberta/lista-inscricao-aberta.component'
import { ListaInscricaoAndamentoComponent } from './modules/concurso/lista-inscricao-andamento/lista-inscricao-andamento.component'
import { ListaInscricaoEncerradaComponent } from './modules/concurso/lista-inscricao-encerrada/lista-inscricao-encerrada.component'
import { ListaInscricaoProximaComponent } from './modules/concurso/lista-inscricao-proxima/lista-inscricao-proxima.component'
import { AndamentoComponent } from './modules/concurso/andamento/andamento.component'
import { ConcursoComponent } from './modules/concurso/concurso/concurso.component'
import { FichaInscricaoComponent } from './modules/concurso/ficha-inscricao/ficha-inscricao.component'
import { AuthComponent } from './modules/sistema/auth/auth.component'
import { SenhaRecuperarComponent } from './modules/sistema/senha-recuperar/senha-recuperar.component'
import { SenhaRedefinirComponent } from './modules/sistema/senha-redefinir/senha-redefinir.component'
import { TokenHttpInterceptor } from './interceptors/token-http.interceptor'
import { LoaderHttpInterceptor } from './interceptors/loader-http.interceptor'
import { CandidatoFieldsetInscricaoComponent } from './modules/candidato/candidato-fieldset-inscricao.component/candidato-fieldset-inscricao.component'
import { CandidatoInscricoesComponent } from './modules/candidato/candidato-inscricoes/candidato-inscricoes.component'
import { CandidatoInscricaoFichaComponent } from './modules/candidato/candidato-inscricao-ficha/candidato-inscricao-ficha.component'
import { CandidatoInscricaoComplementoFase1Component } from './modules/candidato/candidato-inscricao-complemento-fase1/candidato-inscricao-complemento-fase1.component'
import { CandidatoInscricaoComplementoFase2Component } from './modules/candidato/candidato-inscricao-complemento-fase2/candidato-inscricao-complemento-fase2.component'
import { SenhaAlterarComponent } from './modules/candidato/senha-alterar/senha-alterar.component'
import { FormularioSituacaoComponent } from './modules/formulario/formulario-situacao/formulario-situacao.component'
import { FormularioFileComponent } from './modules/formulario/formulario-file/formulario-file.component'
import { FormularioCheckboxComponent } from './modules/formulario/formulario-checkbox/formulario-checkbox.component'
import { ModalModule } from './modules/formulario/file-preview-modal/modal.module'
import { PaginationModule } from './modules/pagination/pagination.module'
import { LoaderModule } from './modules/loader/loader.module'
import { PagamentoDepositoComponent } from './modules/candidato/pagamento-deposito/pagamento-deposito.component'
import { PagamentoBoletoComponent } from './modules/candidato/pagamento-boleto/pagamento-boleto.component'
import { PagamentoDevolucaoComponent } from './modules/candidato/pagamento-devolucao/pagamento-devolucao.component'
import { RecursoCadastrarComponent } from './modules/candidato/recurso-cadastrar/recurso-cadastrar.component'
import { RecursoListarComponent } from './modules/candidato/recurso-listar/recurso-listar.component'
import { RecursoVisualizarComponent } from './modules/candidato/recurso-visualizar/recurso-visualizar.component'
import { ContatoComponent } from './modules/candidato/contato/contato.component'
import { DocumentosComponent } from './modules/candidato/inscricao-documentos/documentos.component'
import { ManualComponent } from './modules/sistema/manual/manual.component'
import { BoletoModalModule } from './modules/candidato/boleto-preview-modal/boleto-modal.module'
import { CandidatoInscricaoNotasComponent } from './modules/candidato/candidato-inscricao-notas/candidato-inscricao-notas.component'
import { InscricaoNotaCriterioComponent } from './modules/candidato/candidato-inscricao-notas/inscricao-nota-criterio/inscricao-nota-criterio.component'
import { PagamentoPixComponent } from './modules/candidato/pagamento-pix/pagamento-pix.component'
import { PixModalModule } from './modules/candidato/pix-preview-modal/pix-modal.module'
import { PagamentoComprovanteComponent } from './modules/candidato/pagamento-comprovante/pagamento-comprovante.component'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    ToastComponent,
    ToasterComponent,
    ListaInscricaoAbertaComponent,
    ListaInscricaoAndamentoComponent,
    ListaInscricaoEncerradaComponent,
    ListaInscricaoProximaComponent,
    AndamentoComponent,
    DocumentosComponent,
    ConcursoComponent,
    FichaInscricaoComponent,
    AuthComponent,
    SenhaRecuperarComponent,
    SenhaRedefinirComponent,
    CandidatoFieldsetInscricaoComponent,
    CandidatoInscricoesComponent,
    CandidatoInscricaoFichaComponent,
    CandidatoInscricaoComplementoFase1Component,
    CandidatoInscricaoComplementoFase2Component,
    CandidatoInscricaoNotasComponent,
    InscricaoNotaCriterioComponent,
    SenhaAlterarComponent,
    FormularioSituacaoComponent,
    FormularioFileComponent,
    FormularioCheckboxComponent,
    PagamentoDepositoComponent,
    PagamentoBoletoComponent,
    PagamentoPixComponent,
    PagamentoDevolucaoComponent,
    RecursoCadastrarComponent,
    RecursoListarComponent,
    RecursoVisualizarComponent,
    PagamentoComprovanteComponent,
    ContatoComponent,
    ManualComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ModalModule,
    BoletoModalModule,
    PixModalModule,
    PaginationModule,
    LoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderHttpInterceptor, multi: true },
    DatePipe,
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
