<section>
  <h2 style="display: none">{{'contato.contato' | translate}}</h2>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/candidato/inscricoes" title="{{'shared.area_do_candidato' | translate}}">{{'shared.area_do_candidato' | translate}}</a>
        </li>
        <li class="breadcrumb-item active">{{'contato.contato' | translate}}</li>
      </ol>
    </nav>
    <form [formGroup]="form">
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">{{'contato.contato' | translate}}</legend>
        <div class="mb-3 mt-3">
          <div class="input-group">
            <label class="input-group-text">{{'shared.concurso' | translate}}</label>
            <input class="form-control" value="{{ concursoTitulo }}" type="text" readonly="readonly" />
          </div>
        </div>
        <div class="mb-3">
          <div class="input-group">
            <label class="input-group-text">Email</label>
            <input class="form-control" formControlName="email" type="text" readonly="readonly" maxlength="50" />
          </div>
        </div>
        <div class="mb-3">
          <div class="input-group">
            <label class="input-group-text">{{'contato.nome' | translate}}</label>
            <input class="form-control" formControlName="nome" type="text" maxlength="80" />
          </div>
        </div>
        <div>
          <div class="input-group">
            <label class="input-group-text">{{'contato.mensagem' | translate}}</label>
            <textarea class="form-control" formControlName="mensagem" rows="4"></textarea>
          </div>
        </div>
      </fieldset>
      <div class="row mt-3">
        <div class="col-12 d-flex flex-row-reverse">
          <a (click)="enviar()" class="btn btn-primary btn-sm">
            <i class="bi bi-check-circle" aria-hidden="true">&nbsp;</i> <span>{{'shared.enviar' | translate}}</span>
          </a>
        </div>
      </div>
    </form>
  </div>
</section>
