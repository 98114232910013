/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
})
export class PaginationComponent implements OnChanges {
  @Input() current: number = 0
  @Input() totalPages: number = 0
  @Input() totalElements: number = 0
  @Output() goTo: EventEmitter<number> = new EventEmitter<number>()
  @Output() next: EventEmitter<number> = new EventEmitter<number>()
  @Output() previous: EventEmitter<number> = new EventEmitter<number>()
  public pages: number[] = []

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['current'] && changes['current'].currentValue) ||
      (changes['totalPages'] && changes['totalPages'].currentValue) ||
      (changes['totalElements'] && changes['totalElements'].currentValue)
    ) {
      this.pages = this.getPages(this.current, this.totalPages)
    }
  }

  public onGoTo(page: number): void {
    if (page !== this.current) {
      this.goTo.emit(page)
    }
  }

  public onNext(): void {
    this.next.emit(this.current)
  }

  public onPrevious(): void {
    this.previous.next(this.current)
  }

  private getPages(current: number, totalPages: number): number[] {
    if (totalPages <= 7) {
      return [...Array(totalPages).keys()].map(x => ++x)
    }

    if (current > 5) {
      if (current >= totalPages - 4) {
        return [1, -1, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]
      } else {
        return [1, -1, current - 1, current, current + 1, -1, totalPages]
      }
    }

    return [1, 2, 3, 4, 5, -1, totalPages]
  }
}
