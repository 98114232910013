/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Input, OnInit } from '@angular/core'
import { BoletoModalService } from '../services/boleto-modal.service'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { BoletoPreview } from '../models/boleto-preview'

@Component({
  selector: 'app-boleto-modal',
  templateUrl: './boleto-modal.component.html',
  styleUrls: ['./boleto-modal.component.css'],
})
export class BoletoModalComponent implements OnInit {
  boletoPreview?: BoletoPreview
  exibir: boolean = false
  @Input() trustedUrl!: SafeUrl
  windowHeight: number = 400

  constructor(private sanitizer: DomSanitizer, private boletoModalService: BoletoModalService) {}

  ngOnInit(): void {
    this.windowHeight = window.innerHeight - 200
    this.subscribeToModal()
  }

  subscribeToModal() {
    this.boletoModalService.boletoModalEventsArquivo.subscribe(boletoPreview => {
      this.boletoPreview = boletoPreview
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(boletoPreview.url)
      this.toggle()
    })
  }

  toggle(): void {
    this.exibir = !this.exibir
  }
}
