/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UsuarioService } from 'src/app/services/usuario.service'
import { ToastService } from '../../toast/services/toast.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-senha-recuperar',
  templateUrl: './senha-recuperar.component.html',
  styleUrls: ['./senha-recuperar.component.css'],
})
export class SenhaRecuperarComponent implements OnInit {
  form = new FormGroup({
    email: new FormControl('', Validators.required),
  })

  i18n = {
    atencao: '',
    erroFormulario: '',
    orientacoes: '',
    email_nao_encontrado: ''
  };

  constructor(
    private usuarioService: UsuarioService,
    private toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.carregarI18n();
    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  enviar() {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroFormulario)
      return
    }

    if (this.form.get('email')?.value) {
      let email = this.form.get('email')?.value ?? ''
      this.usuarioService.senhaRecuperar(email).subscribe(email => {
        if (email != '') {
          this.toastService.showSuccessToast(this.i18n.atencao, `${this.i18n.orientacoes} ${email}`)
        } else {
          this.toastService.showErrorToast(this.i18n.atencao, this.i18n.email_nao_encontrado)
        }
        this.form.reset()
      })
    }
  }

  private carregarI18n(): void {
    this.translateService.get([
      'senha_recuperar.orientacoes', 
      'senha_recuperar.email_nao_encontrado', 
      'shared.atencao', 
      'shared.erro_formulario']
    )
    .subscribe(traducoes => {
      this.i18n.atencao = traducoes['shared.atencao']
      this.i18n.erroFormulario = traducoes['shared.erro_formulario']
      this.i18n.email_nao_encontrado = traducoes['senha_recuperar.email_nao_encontrado']
      this.i18n.orientacoes = traducoes['senha_recuperar.orientacoes']
    })
  }
}
