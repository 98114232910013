/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

export abstract class PaginationBaseComponent {
  public current = 1
  public perPage = 10
  public totalPages = 0
  public totalElements = 0

  public onGoTo(page: number): void {
    this.current = page
    this.getData()
  }

  public onNext(page: number): void {
    this.current = page + 1
    this.getData()
  }

  public onPrevious(page: number): void {
    this.current = page - 1
    this.getData()
  }

  /**
   * @example
   *   let filter: any = { page: (this.current-1), size: this.perPage }
   *   this.{service}.{method}(filter).subscribe(data => {
   *     this.{any[]} = data['content']
   *     this.totalPages = data['totalPages']
   *     this.totalElements = data['totalElements']
   *   })
   */
  abstract getData(): void
}
