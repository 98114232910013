<ng-container *ngIf="exibir">
  <div class="simple-modal">
    <div class="simple-modal-dialog modal-lg">
      <div class="simple-modal-content">
        <div class="simple-modal-header">
          <h5 class="simple-modal-title">
            {{ filePreview?.title }}
            <br />{{ filePreview?.subtitle }}
            <hr />
            {{'modal.arquivo_selecionado' | translate}}: <span class="text-danger">{{ filePreview?.file?.name }}</span>
          </h5>
        </div>
        <div class="simple-modal-body">
          <iframe [src]="trustedUrl" width="100%" height="400" ></iframe>
        </div>
        <div class="simple-modal-footer">
          <div class="d-flex">
            <div class="me-auto">
              <button type="button" class="btn btn-sm btn-secondary" (click)="cancelar()">{{'modal.cancelar' | translate}}</button>
            </div>
            <div class="ms-auto">
              <button type="button" class="btn btn-lg btn-primary" (click)="enviar()">{{'shared.enviar' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div (click)="toggle()" class="overlay"></div>
</ng-container>
