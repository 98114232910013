/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { RecursoService } from 'src/app/services/recurso.service'
import { ToastService } from '../../toast/services/toast.service'
import { RecursosDisponiveis } from 'src/app/models/recursos-disponiveis'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-recurso-cadastrar',
  templateUrl: './recurso-cadastrar.component.html',
  styleUrls: ['./recurso-cadastrar.component.css'],
})
export class RecursoCadastrarComponent implements OnInit {
  id: number = 0
  recursosDisponiveis?: RecursosDisponiveis
  conferencia: boolean | null = false
  file?: File | null
  inputClass: string = 'form-control'
  i18n = {
    atencao: '',
    erroFormulario: '',
    sucessoCadastro: '',
    erroCadastro: '',
    confiraDados: '',
    erroRegistrarArquivo: '',
    arquivoExcedeu: '',
    arquivoTipo:''
  };

  constructor(
    private route: ActivatedRoute,
    private recursoService: RecursoService,
    private toastService: ToastService,
    private router: Router,
    private location: Location,
    private readonly translateService: TranslateService
  ) {}

  form = new FormGroup({
    tipoRecurso: new FormControl('', Validators.required),
    texto: new FormControl('', Validators.required),
    anexo: new FormControl(),
  })

  ngOnInit(): void {
    this.carregarI18n();
    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });
    
    this.route.params.subscribe(params => {
      this.id = params['id']
      this.form.reset()
      this.recursoService.recursosDisponiveis(this.id).subscribe(recursosDisponiveis => {
        this.recursosDisponiveis = recursosDisponiveis
      })
    })
  }

  onFileSelected(event: any): void {
    this.file = event.target.files[0]
    if (this.file) {
      if (this.file.type !== 'application/pdf' && this.file.type !== 'image/png' && this.file.type !== 'image/jpeg') {
        this.inputClass = 'form-control is-invalid'
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.arquivoTipo)
        this.file = null
        this.form.controls['anexo'].reset()
        return
      }
      if (this.file.size > 512000000) {
        this.inputClass = 'form-control is-invalid'
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.arquivoExcedeu)
        this.file = null
        this.form.controls['anexo'].reset()
        return
      }
      this.inputClass = 'form-control is-valid'
    }
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  voltar(): void {
    this.location.back()
  }

  cancelar(): void {
    this.conferencia = false
    this.form.enable()
  }

  conferir(): void {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroFormulario)
      return
    }

    this.conferencia = null
    setTimeout(() => {
      this.conferencia = true
    }, 300)
    this.form.disable()
    this.toastService.showWarningToast(this.i18n.atencao, this.i18n.confiraDados)
  }

  enviar(): void {
    var jsonData = {
      tipoRecurso: this.form.get('tipoRecurso')?.value,
      texto: this.form.get('texto')?.value,
    }
    this.recursoService.novoRecurso(this.id, jsonData).subscribe(recurso => {
      if (recurso) {
        const formData = new FormData()
        if (this.file) {
          formData.append('file', this.file)
          this.recursoService.anexar(recurso.id, formData).subscribe(recurso => {
            if (!recurso) {
              this.toastService.showErrorToast(
                this.i18n.atencao,
                this.i18n.erroRegistrarArquivo
              )
            }
          })
        }
        this.toastService.showSuccessToast(this.i18n.atencao, this.i18n.sucessoCadastro)
        this.router.navigate(['/candidato/recursos'])
      } else {
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroCadastro)
      }
    })
  }

  private carregarI18n(): void {
    this.translateService.get([
      'shared.atencao',
      'shared.erro_formulario',
      'recurso_cadastrar.erro_cadastro',
      'recurso_cadastrar.sucesso_cadastro',
      'recurso_cadastrar.confira_dados',
      'recurso_cadastrar.erro_registrar_arquivo',
      'arquivo_shared.arquivo_excedeu',
      'arquivo_shared.arquivo_tipo'
    ])
    .subscribe(traducoes =>{
      this.i18n.atencao = traducoes['shared.atencao']
      this.i18n.erroFormulario = traducoes['shared.erro_formulario']
      this.i18n.sucessoCadastro = traducoes['recurso_cadastrar.sucesso_cadastro']
      this.i18n.erroCadastro = traducoes['recurso_cadastrar.erro_cadastro']
      this.i18n.confiraDados = traducoes['recurso_cadastrar.confira_dados']
      this.i18n.erroRegistrarArquivo = traducoes['recurso_cadastrar.erro_registrar_arquivo']
      this.i18n.arquivoExcedeu = traducoes['arquivo_shared.arquivo_excedeu']
      this.i18n.arquivoTipo = traducoes['arquivo_shared.arquivo_tipo']
    })
  }
}
