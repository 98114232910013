/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { identity, Observable } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { environment } from '../../environments/environment'
import { Recurso } from '../models/recurso'
import { RecursosDisponiveis } from '../models/recursos-disponiveis'
import { RecursoDetalhes } from '../models/recurso-detalhes'

@Injectable({
  providedIn: 'root',
})
export class RecursoService {
  private baseUrl: string

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {
    this.baseUrl = environment.baseUrlApi
  }

  recursosDisponiveis(idInscricao: number): Observable<RecursosDisponiveis> {
    return this.http
      .get<RecursosDisponiveis>(`${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/recurso`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  novoRecurso(idInscricao: number, jsonData: any): Observable<Recurso> {
    return this.http
      .post<Recurso>(`${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/recurso`, jsonData)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  anexar(id: number, formData: FormData): Observable<Recurso> {
    return this.http
      .post<Recurso>(`${this.baseUrl}/privado/candidato/recurso/${id}/anexo`, formData)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  listar(): Observable<Recurso[]> {
    return this.http
      .get<Recurso[]>(`${this.baseUrl}/privado/candidato/recurso`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  get(id: number): Observable<RecursoDetalhes> {
    return this.http
      .get<RecursoDetalhes>(`${this.baseUrl}/privado/candidato/recurso/${id}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
