/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, inject } from '@angular/core'
import { Recurso } from 'src/app/models/recurso'
import { RecursoService } from 'src/app/services/recurso.service'
import { DownloadService } from '../../../services/download.service'

@Component({
  selector: 'app-recurso-listar',
  templateUrl: './recurso-listar.component.html',
  styleUrls: ['./recurso-listar.component.css'],
})
export class RecursoListarComponent implements OnInit {
  recursos: Recurso[] = []
  loading: boolean = true
  recurso: Recurso | undefined

  constructor(private recursoService: RecursoService, private downloadService: DownloadService) {}

  ngOnInit(): void {
    this.recursoService.listar().subscribe(recursos => {
      this.recursos = recursos
      this.loading = false
    })
  }

  openParecer(): void {
    this.downloadService.anexoParecer(this.recurso?.id ?? 0)
  }
}
